import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger); 
 
jQuery(document).ready(function ($) {
	consoleMessage: {
		console.log('%cCreated by %cTWK%cwww.thewebkitchen.co.uk', 'background: #13212E; color: #FFFFFF; padding: 5px 0px 5px 10px; margin: 25px 0px;', 'background: #13212E; color: #05E5C8; padding: 5px 10px 5px 0px; font-weight: bold;', 'background: #FFFFFF; padding: 5px 10px;');
	}

	modernizer: {
		if (!Modernizr.objectfit) {
			$('.object-fit').each(function () {
				var $container = $(this),
					imgUrl = $container.find('img').prop('src');
				if (imgUrl) {
					$container
						.css('backgroundImage', 'url(' + imgUrl + ')')
						.addClass('not-compatible');
				}
			});
		}
	}

	fixedheader: {
		$(function () {
			$(window).scroll(function () {
				if ($(window).scrollTop() >= 150) {
					$('.header').addClass('fixed-header');
				}
				if ($(window).scrollTop() >= 160) {
					$('.header').addClass("transform-none");
				}
				else {
					$('.header').removeClass('fixed-header');
					$('.header').removeClass("transform-none");
				}
			});
		});
	}

	search: {
		$('.search-icon').on('click', function (e) {
			$('.searchform').toggleClass('search-open');
			$(this).toggleClass('submit-zindex');
			$('.select-lang').removeClass('transform-height');
			$('#menu-main-menu-top, .lang_dropdown').fadeOut();
			$("#s").focus();
			setTimeout(function () {
				$('.close-search').fadeIn();
			}, 300);
		});
		$('.close-search').on('click', function (e) {
			$(this).fadeOut();
			$('.searchform').removeClass('search-open');
			$('.search-icon').removeClass('submit-zindex');
			$('#menu-main-menu-top, .lang_dropdown').fadeIn();
		});


		//change search value
		function changeSearch() {
			$('.search-submit').replaceWith('<button class="search-submit" type="submit"></button>')
		}
		changeSearch();
	}

	menu: {


		// move sub menu menu items into new div
		var subMenuList = document.querySelectorAll(".sub-menu");
		var subMenuContainer = $('.sub-menu-container');

		function moveSubMenu() {
			$('.sub-menu').each(function () {
				$(this).appendTo('.sub-menu-container');
			});
		}
		moveSubMenu();

		// add arrow to end of parent menu items
		var menuContainer = $('.menu-main-menu-container');
		var mainMenu = $('#main-menu');
		var menuItem = mainMenu.find($('.menu-item-has-children'));
		var toInsert = $('<div class="site-menu__list-arrow"></div>')

		function columnMenu() {

			menuContainer.find($('li.menu-item a')).addClass('d-flex site-menu__list-link');
			menuItem.find(('> a')).append(toInsert);

		}

		columnMenu();

		// Open submenu on click
		$('.top-nav .menu-item-has-children > a').on('click', function (e) {
			e.preventDefault();
			var thisID = $(this).parent().attr('id');


			$('.menu-item-has-children').removeClass('sub-menu-open'); // reset
			$('.sub-menu-wrap').removeClass('sub-menu-open');          // reset
			$('.sub-menu-container').removeClass('mobile-z-index');          // reset
			$('.site-menu-wrapper > .site-menu').removeClass('no-scroll');          // reset


			$('.sub-menu').removeClass('sub-menu-open');
			$('.site-menu__lhs').removeClass("menu-open--zindex");


			$(this).closest('.menu-item-has-children').toggleClass('sub-menu-open');
			$('.sub-menu-container').find($('.sub-' + thisID)).toggleClass('sub-menu-open');
			$(this).next('.sub-menu-wrap').toggleClass('sub-menu-open');
			$('.site-menu__lhs').toggleClass("menu-open--zindex");
			$('.sub-menu-container').toggleClass('mobile-z-index');
			$('.site-menu-wrapper > .site-menu').toggleClass('no-scroll');          // reset

		});
		// Open menu with tabs - accessibility
		$('.top-nav .sub-menu-wrap a').on('focusin', function () {
			$(this).closest('.menu-item-has-children').addClass('sub-menu-open');
			$(this).closest('.sub-menu-wrap').addClass('sub-menu-open');


			// console.log($(this));
		});
		$('.top-nav .sub-menu-wrap a').on('focusout', function () {
			$(this).closest('.menu-item-has-children').removeClass('sub-menu-open');
			$(this).closest('.sub-menu-wrap').removeClass('sub-menu-open');
		});
		// Close on click outside.
		$(document).on('click', function (e) {
			if (!$(e.target).parent().hasClass('menu-item') && $('.sub-menu-wrap').has(e.target).length === 0) {
				$('.menu-item-has-children').removeClass('sub-menu-open');
				$('.sub-menu-wrap').removeClass('sub-menu-open');
				$('.sub-menu-container').find($('.sub-menu')).removeClass('sub-menu-open');
				$('.site-menu__lhs').removeClass("menu-open--zindex");
				setTimeout(function () {
					$('.sub-menu-container').removeClass('mobile-z-index');
					$('.site-menu-wrapper > .site-menu').removeClass('no-scroll');          // reset
				}, 500);

			}
		});


		// open menu
		var count = 0;

		$('.site-menu-wrapper .top-nav__hamburger').on('click', function (e) {
			count++;
			$('.site-menu-wrapper').toggleClass('menu-open');
			$('.button--hamburger').toggleClass('open');
			$('body').toggleClass('no-scroll');

			var parentID = $('.site-menu-wrapper').find($('.menu-item-has-children.current-page-ancestor')).attr('id');

			if ($('body').hasClass('page-child')) {
				// console.log($( '.site-menu-wrapper' ).find($('.menu-item-has-children.current-page-ancestor')));
				setTimeout(
					function () {
						$('.site-menu-wrapper').find($('.menu-item-has-children.current-page-ancestor')).addClass('sub-menu-open');
						$('.sub-menu-container').find($('.sub-' + parentID)).addClass('sub-menu-open');
						$('.site-menu__lhs').addClass("menu-open--zindex");
						$('.sub-menu-container').addClass('mobile-z-index');
						$('.site-menu-wrapper > .site-menu').addClass('no-scroll');          // reset
					},
					600
				);

			}
			if(count == 1) {
			setTimeout(function() {
				gsap.from($('.circle-link'), {opacity: 0, duration: 0.7, stagger: 0.5});
			},700)
			}
	

			// MENU and CLOSE text
			$('.top-nav__hamburger #menu-text').css('transition', 'all .4s ease-out');

			if ($('.site-menu-wrapper').hasClass('menu-open')) {
				$('.top-nav__hamburger #menu-text').text('').css('opacity', '0');

				setTimeout(
					function () {
						$('.top-nav__hamburger #menu-text').text('CLOSE').css('opacity', '1').css('font-size', '11px').css('margin-top', '2px').css('margin-left', '-1px');
					},
					400
				);
			} else {
				$('.top-nav__hamburger #menu-text').text('').css('opacity', '0');

				setTimeout(
					function () {
						$('.top-nav__hamburger #menu-text').text('MENU').css('opacity', '1').css('font-size', '');
					},
					400
				);
			}



			// Menu closes on click outside.
			let menu = $('.site-menu-wrapper');

			if (menu.hasClass('menu-open')) {
				$(document.body).on('click', function (e) {

					if (!menu.is(e.target) && menu.has(e.target).length === 0) {
						//console.log('NOT the menu');

						$('.site-menu-wrapper').removeClass('menu-open');
						$('body').removeClass('noscroll');

						$(document.body).off('click');
					}

				});
			}
		});
	}
	

	anchorlinks: {
		$(document).on('click', '.scroll-to', function (event) {
			event.preventDefault();

			$('html, body').animate({
				scrollTop: $($.attr(this, 'href')).offset().top
			}, 500);
		});
	}

	externallinks: {
		$('a[href^="mailto:"]').each(function () {
			$(this).addClass('email-link');
		});

		$('a:not(.email-link):not([class^="magnific-"])').each(function () {
			var a = new RegExp('/' + window.location.host + '/');

			if (!a.test(this.href)) {
				$(this).click(function (event) {
					event.preventDefault();
					window.open(this.href, '_blank');
				});
			}
		});
		pdfs: {
			// Open PDFs in new window
			$(function () {
				$('a[href$=".pdf"]').prop('target', '_blank');
			});
		}
	}

	buttonEffects: {
		// Get width of button and round up to nearest 100
		$('.button').each(function () {

			var buttonWidth = $(this).outerWidth();
			var buttonWidthRounded = Math.ceil(buttonWidth / 100) * 100;

			// Different classes for colours
			let colorClass;
			if ($(this).hasClass('button--white')) {
				colorClass = 'button-wrapper--white';
			} else {
				colorClass = 'button-wrapper--primary';
			}
			// Add wrappers to make hover function work
			$(this).wrap('<div class="button-wrapper ' + colorClass + ' button-width-' + buttonWidthRounded + '"></div>');
			$(this).wrapInner('<span class="button--text"></span>');
			$('.button-wrapper').prepend('<span class="button--circle"></span>');


			// Hover effect
			$(this).mouseenter(function (e) {
				var parentOffset = $(this).offset();
				var relX = e.pageX - parentOffset.left;
				var relY = e.pageY - parentOffset.top;

				$(this).prev(".button--circle").css({ "left": relX, "top": relY });
				$(this).prev(".button--circle").removeClass("implode-circle");
				$(this).prev(".button--circle").addClass("explode-circle");
			});

			$(this).mouseleave(function (e) {
				var parentOffset = $(this).offset();
				var relX = e.pageX - parentOffset.left;
				var relY = e.pageY - parentOffset.top;

				$(this).prev(".button--circle").css({ "left": relX, "top": relY });
				$(this).prev(".button--circle").removeClass("explode-circle");
				$(this).prev(".button--circle").addClass("implode-circle");
			});

		});
		$('.btn').each(function () {
			// Get width of button and round up to nearest 100
			var btnWidth = $(this).outerWidth();
			var btnWidthRounded = Math.ceil(btnWidth / 100) * 100;

			// Add wrappers to make hover function work
			$(this).wrap('<div class="button-wrapper button-width-' + btnWidthRounded + '"></div>');
			$(this).wrapInner('<span class="button--text"></span>');
			$('.button-wrapper').prepend('<span class="button--circle"></span>');


			// Hover effect
			$(this).mouseenter(function (e) {
				var parentOffset = $(this).offset();
				var relX = e.pageX - parentOffset.left;
				var relY = e.pageY - parentOffset.top;

				$(this).prev(".button--circle").css({ "left": relX, "top": relY });
				$(this).prev(".button--circle").removeClass("implode-circle");
				$(this).prev(".button--circle").addClass("explode-circle");
			});

			$(this).mouseleave(function (e) {
				var parentOffset = $(this).offset();
				var relX = e.pageX - parentOffset.left;
				var relY = e.pageY - parentOffset.top;

				$(this).prev(".button--circle").css({ "left": relX, "top": relY }); 
				$(this).prev(".button--circle").removeClass("explode-circle");
				$(this).prev(".button--circle").addClass("implode-circle");
			});
		});

		// https://codepen.io/animationbro/details/dyoVQRz
	}


	slider: {
		$('.full-width-slider').slick({
			dots: true,
			arrows: false,
			fade: true,
			autoplay: true,
			autoplaySpeed: 3500,
			speed: 1200,
		});
		$('.js-text-image-slider').on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
			//currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
			var i = (currentSlide ? currentSlide : 0) + 1;
			var j = (currentSlide ? currentSlide : 0);
			// var initNum = (i).slice(-2)
			var currentNum = ('0' + i).slice(-2)
			var totalNum = (slick.slideCount)
			$('.block--text-image-slider .js-slider-pagi').text(i + ' OF ' + totalNum);
			
			var currHeight = $('.block--text-image-slider').height() - $('[data-slick-index=' + j +'] .slide--text').height() - 130;
			// if(window.innderWidth > 768) {
			// 	$('.slider__pagi--arrows').css('top', currHeight);
			// }

			// $('[data-slick-index=' + (j) +'] .slide--title').css('margin-left', '0px');
			// $('[data-slick-index=' + (j) +'] .slide--title').css('text-align', 'center');
			// // left and right
			// $('[data-slick-index=' + (j + 1) +'] .slide--title').css('margin-left', '-120px');
			// $('[data-slick-index=' + (j + 1) +'] .slide--title').css('text-align', 'left');
			// $('[data-slick-index=' + (j - 1) +'] .slide--title').css('margin-right', '-120px');
			// $('[data-slick-index=' + (j - 1) +'] .slide--title').css('text-align', 'right');
		}); 		
		$('.js-text-image-slider').slick({
			dots: false,
			arrows: true,
			fade: false,
			autoplay: false, 
			autoplaySpeed: 3500,
			speed: 1200,
			slidesToShow: 1,
			rows: 3,
			adaptiveHeight: true,
			prevArrow: $('.block--text-image-slider .prev-slide'),
			nextArrow: $('.block--text-image-slider .next-slide'),
		});
		$('.js-discover-slider').slick({
			slidesToShow: 1,
			initialSlide: 0,
			slidesToScroll: 1,
			adaptiveHeight: false,
			arrows: false,
			dots: true,
			autoplay: true,
			autoplaySpeed: 6000,

		});
		$('.js-gallery-slider').slick({
			slidesToShow: 2,
			initialSlide: 0,
			slidesToScroll: 1,
			adaptiveHeight: false,
			arrows: true,
			dots: false,
			autoplay: true,
			autoplaySpeed: 6000,
			responsive: [
				{
					breakpoint: 575,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					}
				},
			]

		});
		//gallery slider arrows
		const sliderParent = $('.js-gallery-slider').closest('section')
		const prevButton = sliderParent.find('.js-prev');
		const nextButton = sliderParent.find('.js-next');
		prevButton.on('click', () => $('.js-gallery-slider').slick('slickPrev'));
		nextButton.on('click', () => $('.js-gallery-slider').slick('slickNext'));

		/**
		 * Gallery slider with thumbnails navigation.
		 */
		$('.gallery-slider').each(function (index) {
			var status = $(this).find('.gallery-slider__full-nav .pages');
			var slickElement = $(this).find('.gallery-slider__full');
			var slickElementThumb = $(this).find('.gallery-slider__thumb');

			slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
				//currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
				var i = (currentSlide ? currentSlide : 0) + 1;
				status.text(i + ' of ' + slick.slideCount);
			});

			slickElement.slick({
				dots: false,
				arrows: true,
				fade: true,
				asNavFor: $(this).find('.gallery-slider__thumb'),
				appendArrows: $(this).find('.gallery-slider__full-nav'),
			});

			slickElementThumb.slick({
				dots: false,
				arrows: true,
				slidesToShow: 4,
				// centerMode: true,
				focusOnSelect: true,
				asNavFor: $(this).find('.gallery-slider__full'),
			});
		});
	}

	admissionsProcess: {
		function processStepsFixed() {
			if($('.scroll-tabs__menu').length) {		
				var topValue = $('.scroll-tabs__menu').offset().top;
				$(window).scroll(function () {
					var scrollTop = $(document).scrollTop();
					var maxTop = ($('.scroll-tabs').offset().top + $('.scroll-tabs').height()) - $('.scroll-tabs__menu__sticky').height();
					
					if(scrollTop >= maxTop) {
						$('.scroll-tabs__menu__sticky').css('position', 'absolute');
						$('.scroll-tabs__menu__sticky').css('top', $('.scroll-tabs__menu').height() - $('.scroll-tabs__menu__sticky').height() );
					} else {
					if(scrollTop >= topValue && $(window).width() > 991) {
						$('.scroll-tabs__menu__sticky').css('position', 'fixed');
						$('.scroll-tabs__menu__sticky').css('top', '0');
						$('.scroll-tabs__menu__sticky').css('width', $('.scroll-tabs__menu').width());
					} else {
						$('.scroll-tabs__menu__sticky').css('position', 'relative');
					}
				}
				});
			}
		}
		$(window).on('load', function () {
			
			if($(window).width() > 991) {
				
				processStepsFixed()
			}
		});
		$(window).on('resize',  function () {
			
			if($(window).width() > 991) {
				processStepsFixed()
			}
		});
	}

	magnific: {
		// IFRAME
		$('.magnific-video').magnificPopup({
			type: 'iframe',
			mainClass: 'mfp-video-wrapper',
			removalDelay: 160,
			preloader: false,
			fixedContentPos: false,

			iframe: {
				markup: '<div class="mfp-iframe-scaler">' +
					'<div class="mfp-close"></div>' +
					'<iframe class="mfp-iframe" frameborder="0" allow="autoplay"></iframe>' +
					'</div>',
				patterns: {
					youtu: {
						index: 'youtu.be',
						id: function (url) {

							// Capture everything after the hostname, excluding possible querystrings.
							var m = url.match(/^.+youtu.be\/([^?]+)/);

							if (null !== m) {
								return m[1];
							}

							return null;

						},
						// Use the captured video ID in an embed URL. 
						// Add/remove querystrings as desired.
						src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0'
					},
					youtube: {
						index: 'youtube.com/',
						id: 'v=',
						src: 'https://www.youtube.com/embed/%id%?autoplay=1'
					}
				}
			}
		});


		// INLINE
		$('.magnific-inline-popup').magnificPopup({
			type: 'inline',

			fixedContentPos: false,
			fixedBgPos: true,

			overflowY: 'auto',

			closeBtnInside: true,
			preloader: false,

			midClick: true,
			removalDelay: 300,
			mainClass: 'my-mfp-zoom-in'
		});


		// GALLERY SINGLE IMAGE
		$('.magnific-gallery-single-image').magnificPopup({
			type: 'image',
			closeOnContentClick: true,
			mainClass: 'mfp-img-single',
			image: {
				verticalFit: true,
				titleSrc: function titleSrc(item) {
					return item.el.attr('title');
				}
			},
			gallery: {
				enabled: false
			}
		});

		// GALLERY IMAGE
		$('.magnific-gallery-image').magnificPopup({
			type: 'image',
			closeOnContentClick: true,
			mainClass: 'mfp-img-mobile',
			image: {
				verticalFit: true,
				titleSrc: function (item) {
					return item.el.attr('title');
				}
			},
			gallery: {
				enabled: true
			}
		});
	}
	ghostWord: {
		// make every second word ghost lettering
		var toGhost = $('.ghost-word');

		// console.log(toGhost);
		for (var i = 0; i < toGhost.length; ++i) {
			ghostSecondWord(toGhost[i]);
		}

		function ghostSecondWord(elem) {
			elem.innerHTML = elem.textContent.replace(/\w+ (\w+)/, function (s, c) {
				return s.replace(c, '<span class="font-ghost">' + c + '</span>');
			});
		}


		// HALF word ghost
		var halfGhost = $('.half-ghost');

		// round to nearest even number

		for (var i = 0; i < halfGhost.length; ++i) {

			var ghostArray = ($(halfGhost[i])).text().split(' ')
			var ghostSplit = 2 * Math.round(ghostArray.length / 2) / 2;			
			ghostHalfWord(halfGhost[i], ghostSplit, ghostArray);
			
		}
		function ghostHalfWord(elem, ghostSplit, ghostArray) {			
			var toGhost = ghostArray.slice(ghostSplit);
			var toGhostString = toGhost.join(" ");
			ghostArray.splice(ghostSplit, 0, '<span class="font-ghost">' + toGhostString + '</span>');
			
			var len = ghostArray.length;
			
			var newText = ghostArray.slice(0, -1 * (len - (ghostSplit + 1) ) )
			console.dir(newText);
			elem.innerHTML = newText.join(" ");

			// elem.innerHTML = elem.textContent.replace(RegExp("(\\w+\\s+){" + ghostSplit + "}"), function (s, c) {				
			// 	return s.replace(s, '<span class="font-ghost">' + s + '</span>');				
			// });
		}

	}

	changeImages: {
		var hoverSlide = document.querySelectorAll('.js-hover-slide');
		var images = document.querySelectorAll('.hover-image');

		for (let i = 0; i < hoverSlide.length; i++) {
			(function (index) {
				
				hoverSlide[index].addEventListener("mouseenter", function () {
					for (let k = 0; k < hoverSlide.length; k++) {
						if(k > index) {
							$(hoverSlide[k]).removeClass('hovercolour');
							$(images[k]).css('opacity', 0);

						}
					}
					$(images[index]).css('opacity', 1);
					
					for (let j = 0; j < index; j++) {
						$(hoverSlide[j]).addClass('hovercolour');
					}

					$(hoverSlide[index]).addClass('hovercolour');

				})
				// hoverSlide[index].addEventListener("mouseleave", function () {
				// 	$(images[index]).css('opacity', 0);

				// 	for (let j = 0; j < index; j++) {
				// 		$(hoverSlide[j]).removeClass('hovercolour');
				// 	}

				// 	$(hoverSlide[index]).removeClass('hovercolour');
				// })
			})(i);
		}
	}
	// staffMember: {
	// 	if($('.block--staff-members').length) {
	// 		$('.staff-member__card').each(function() {
	// 			$(this).find('.staff-member__link').mouseenter(function() {
	// 				$(this).find('.staff-member__hide').slideUp('fast');
	// 			})

	// 			$(this).find('.staff-member__link').mouseleave(function() {
	// 				$(this).find('.staff-member__hide').slideDown('fast');
	// 			})
				
	// 		});
	// 	}
	// }
	accordion: {
		if ($('.block--accordion').length) {

			$('.accordion__content--wrapper').css('display', 'none'); // All items closed.

			$('.block--accordion').find('.js-accordion-trigger').on('click', function () {

				// Close only the items in this accordion.
				$(this).parent().siblings().find('.js-accordion-trigger').next().slideUp('fast'); // reset
				$(this).parent().siblings().find('.js-accordion-trigger').removeClass('open');    // reset

				// Close items in all accordions on the page.
				//$('.js-accordion-trigger').not(this).next().slideUp('fast'); // reset
				//$('.js-accordion-trigger').not(this).removeClass('open');    // reset

				if ($(this).hasClass('open')) {
					$(this).next().slideUp('fast');
					$(this).removeClass('open');
				} else {
					$(this).next().slideDown('fast');
					$(this).addClass('open');
				}

			});
		}
		if ($('.block--accordion-staff-members').length) {

			$('.accordion__content--wrapper').css('display', 'none'); // All items closed.

			$('.block--accordion-staff-members').find('.js-accordion-trigger').on('click', function () {

				// Close only the items in this accordion.
				$(this).parent().siblings().find('.js-accordion-trigger').next().slideUp('fast'); // reset
				$(this).parent().siblings().find('.js-accordion-trigger').removeClass('open');    // reset

				// Close items in all accordions on the page.
				//$('.js-accordion-trigger').not(this).next().slideUp('fast'); // reset
				//$('.js-accordion-trigger').not(this).removeClass('open');    // reset

				if ($(this).hasClass('open')) {
					$(this).next().slideUp('fast');
					$(this).removeClass('open');
				} else {
					$(this).next().slideDown('fast');
					$(this).addClass('open');
				}

			});
		}
	}

	 tabs: {
		const tabs = document.querySelectorAll('[role="tab"]');
		const tabList = document.querySelector('[role="tablist"]');

		if (tabs) {

			// Add a click event handler to each tab
			tabs.forEach(tab => {
				if (!tab.closest('[role="tablist"]').classList.contains('slick-dots')) {
					tab.addEventListener("click", changeTabs);
				}
			});

			// Enable arrow navigation between tabs in the tab list
			let tabFocus = 0;

			if (tabList) {
				tabList.addEventListener("keydown", e => {
					// Move right
					if (e.keyCode === 39 || e.keyCode === 37) {
						tabs[tabFocus].setAttribute("tabindex", -1);
						if (e.keyCode === 39) {
							tabFocus++;
							// If we're at the end, go to the start
							if (tabFocus >= tabs.length) {
								tabFocus = 0;
							}
							// Move left
						} else if (e.keyCode === 37) {
							tabFocus--;
							// If we're at the start, move to the end
							if (tabFocus < 0) {
								tabFocus = tabs.length - 1;
							}
						}

						tabs[tabFocus].setAttribute("tabindex", 0);
						tabs[tabFocus].focus();
					}
				});
			}


			function changeTabs(e) {
				e.preventDefault();
				const target = e.target;
				const parent = target.parentNode;
				const grandparent = parent.closest('.tabs');
				setTimeout(function () {
					$('.js-process-slider').slick('refresh');
				}, 100);

				// console.info(grandparent);
				// console.info(parent);
				// Remove all current selected tabs
				grandparent.querySelectorAll('[aria-selected="true"]').forEach(t => t.setAttribute("aria-selected", false));

				// Set this tab as selected
				target.setAttribute("aria-selected", true);

				// Hide all tab panels
				grandparent.querySelectorAll('[role="tabpanel"]').forEach(p => p.setAttribute("hidden", true));

				// Remove 'active' class on all tab panels
				grandparent.querySelectorAll('[role="tabpanel"]').forEach(q => q.classList.remove("active"));

				// Remove 'active' class on all tabs
				grandparent.querySelectorAll('[role="presentation"]').forEach(r => r.classList.remove("active"));

				// Show the selected panel
				console.info(target.getAttribute("aria-controls"));
				console.info(grandparent);
				console.info(grandparent.parentNode);
				grandparent.parentNode.querySelector(`#${target.getAttribute("aria-controls")}`).removeAttribute("hidden");

				// Add 'active' class on selected tab and tab panel
				grandparent.parentNode.querySelector(`#${target.getAttribute("aria-controls")}`).classList.add("active");
				parent.classList.add("active");
			}


		}
	}
	scrollTabs: {
		$(document).on('click', '.scroll-tabs__tab', function (event) {
		  event.preventDefault();
		  $('html, body').animate({
			scrollTop: $($.attr(this, 'href')).offset().top
		  }, 500);
		});
	  
	  
		$(window).scroll(function () {
		  var scrollTop = $(document).scrollTop();
		  var anchors = $('body').find('.scroll-tabs__panel');
	  
	  
		  for (var i = 0; i < anchors.length; i++) {
			if (scrollTop > $(anchors[i]).offset().top - 242 && scrollTop < $(anchors[i]).offset().top + $(anchors[i]).height() - 242) {
			  $('.scroll-tabs__tab[href="#' + $(anchors[i]).attr('id') + '"]').addClass('active');
			  $('img.image-' + $(anchors[i]).attr('id')).addClass('active');
			} else if ( scrollTop < $('#panel-1').offset().top ) {
			  $('.scroll-tabs__tab[href="#' + $('#panel-1').attr('id') + '"]').addClass('active');
			  $('img.image-' + $('#panel-1').attr('id')).addClass('active');
			} else {
			  $('.scroll-tabs__tab[href="#' + $(anchors[i]).attr('id') + '"]').removeClass('active');
			  $('img.image-' + $(anchors[i]).attr('id')).removeClass('active');
			}
		  }
	  
		});
	  }

	  homepageGradient: {
		if($('.blue-gradient').length) {			

			$('.blue-gradient').each(function() {
				var trigger = this;

				let tl = gsap.timeline({
					// yes, we can add it to an entire timeline!
					scrollTrigger: {
					  trigger: trigger,
					  start: "bottom center+=300px", // when the top of the trigger hits the top of the viewport
					  end: "bottom center"					  ,
					  scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar					  
					}
				  });
				
				tl.to(this, {					
					backgroundColor: 'white',
					
				  });
			})
		}
	}
	loadingAnimations: {
	// twk animate on scroll
	function AnimateOnScroll() {
		const elements = document.querySelectorAll('[twk-aos]');

		if (!elements) return;

		[...elements].forEach(element => {
			const twkAosTrigger = element.getAttribute('twk-aos-trigger') ? element.getAttribute('twk-aos-trigger') : 'bottom-=150px';
			const twkAosMarker = element.hasAttribute('twk-aos-marker');

			ScrollTrigger.create({
				trigger: element,
				start: 'top bottom',
				// end: 'bottom bottom',
				markers: twkAosMarker,
				onEnter: () => element.classList.add('twk-aos-animate'),
			});
		})
	}

	AnimateOnScroll();


	function loadingAnimation() {
		$('.block').each(function() {
			// console.log($(this));
			let trigger = $(this);
			let tl = gsap.timeline({			
				scrollTrigger: {
				  trigger: trigger,
				  start: "top bottom",
				}
			  });
			tl.from($(this), {
				y: 300,
				opacity: 0,
				duration: 0.8
			})
		});
	}

	if(!$('body').hasClass('home')) {
		loadingAnimation();
	}
	//homepage loading

	function homepageAnimations() {
		
		$('section').each(function() {
			let trigger = $(this);
			
			let mainTitle = $(this).find('.js-main-title p');
			let bannerButton = $(this).find('.js-banner-button').parent();
			let bannerVideoButton = $(this).find('.js-banner-video-button');
			let bubbleGrow = $(this).find('.js-bubble-grow');
			let homepageQuote = $(this).find('.js-animate-quote');
			let sliderTitle = $(this).find('.js-slider-title');
			let sliderText = $(this).find('.js-slider-text');
		
			let hPTL = gsap.timeline({			
				scrollTrigger: {
				  trigger: trigger,
				  start: "top center",
				}
			  });
	
			
			if(mainTitle.length> 0) { 
				hPTL.from(mainTitle, {
				y: "40%",
				opacity: 0,
				duration: 1.2,
				stagger: 0.3
			  })
			}
			if(bannerButton.length> 0) {
			  hPTL.from(bannerButton, {
				y: "100%",
				opacity: 0,
				duration: 0.8,
				ease: "power4.inOut"
			  },"<+=0.7")
			}
			if(bannerVideoButton.length> 0) {
			  hPTL.from(bannerVideoButton, {				
				scale: 0,
				duration: 1.4,
				ease: "power3.out",
				onComplete: setTimeout(function() {
					bannerVideoButton.addClass('video-button-loaded');
				},1400)
			  },"<")
			}
			if(bubbleGrow.length> 0) {
			  hPTL.from(bubbleGrow, {
				scale: 0,
				duration: 1.5,
				stagger: 1.2,
				ease: "power3.out"

			  },"<+=0.5")
			}
			if(homepageQuote.length> 0) {
			  hPTL.from(homepageQuote, {
				y: "50%",
				duration: 0.8
			  },"<+=0.6")
			}
			if(sliderTitle.length> 0) {
			  hPTL.from(sliderTitle, {
				y: "60%",
				opacity: 0,
				duration: 0.8,
				delay: 0,
			  })
			}
			if(sliderText.length> 0) {
			  hPTL.from(sliderText, {
				x: "-40%",
				opacity: 0,
				duration: 0.8,
				delay: 0,
			  },">+=0.5")
			}
		})
		
	}
	if($('body').hasClass('home')) {
		homepageAnimations();
	}

		$.fn.isOnScreen = function () {
			var win = $(window);
			var viewport = {
				top: win.scrollTop()
			};
			viewport.bottom = viewport.top + win.height() - 80;

			var bounds = this.offset();
			bounds.bottom = bounds.top + this.outerHeight();

			return (!(viewport.bottom < bounds.top || viewport.top > bounds.bottom));
		};

		// First load Animation
		$('.off-screen').each(function (index) {
			if ($(this).isOnScreen()) {
				$(this).removeClass('off-screen--hide');
			}
		});

		// Animation on scroll
		$(window).scroll(function () {
			$('.off-screen').each(function (index) {
				if ($(this).isOnScreen()) {
					$(this).removeClass('off-screen--hide');
				}
			});
		});
	}
});


